.container {
    border-radius: 16px;
    background-color: #f0f1f2;
    display: inline-block;
    height: 32px;
    line-height: 32px; // Centers texts vertically in pill
    text-align: center;
    color: #2a343d;
    padding: 0 12px;
    cursor: pointer;
    margin: 0 8px 8px 0;

    input {
        display: none;
    }

    label {
        font-family: Lato;
        font-size: 14px;
        cursor: pointer;
    }
}

.selected {
    background-color: #1773cf;
    color: #fff;
    min-width: 40px;

    .checkIcon {
        display: inline-block;
        fill: #fff;
        margin-left: 6px;
    }
}

.disabled {
    background-color: #f0f1f2;
    color: #abafb3;
    cursor: default;

    label {
        cursor: default;
    }
}

.disabled.selected {
    color: #6d747a;

    .checkIcon {
        fill: #6d747a;
    }
}
