@import '~@kmx/legos-theme/src/spacing/functions';

.getStartedButtonContainer {
    margin-top: size(m);

    button {
        border-radius: 8px !important;
        height: 48px !important;
        width: 100%;
    }
}

.vehicleLoadingProgress {
    float: right;
    margin-top: size(xs);
}
