@import '~@kmx/legos-theme/src/colors';

.ico {
    position: relative;
    width: 100%;

    // Global LEGOS form validation style overrides
    :global(.mdc-text-field-helper-text) {
        margin-top: -8px;
    }

    // Global LEGOS form validation style overrides (disabled-looking buttons)
    :global(button.disabled:not(:disabled)) {
        background-color: kmx-color('slate', 200);
        border: none;
        color: kmx-color('slate', 400);
        cursor: default;
        outline-style: none;
    }

    // Global LEGOS disabled text field style override for iOS
    :global(.kmx-text-field input:disabled) {
        opacity: 1;
        -webkit-text-fill-color: kmx-color('slate', 400);
    }
}

.icoHidden {
    display: none;
}
