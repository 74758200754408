@import '~@kmx/legos-theme/src/spacing/functions';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~sass-mq';

.container {
    display: flex;

    .imageContainer,
    .vehicleInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .imageContainer {
        overflow: hidden;
        margin-right: size('m');

        .stockPhoto {
            width: size('3xl');
            height: size('2xl');
            background-size: cover;
            margin-top: -10%;
            margin-bottom: -10%;
        }
    }

    .vehicleInfo {
        text-align: left;

        :global(.kmx-table) {
            display: none;
        }

        .short .vin {
            margin-top: 0;
            margin-bottom: size('xs');
        }

        .vin_white_space {
            white-space: nowrap;
        }
    }
}

.container.fullView {
    display: block;

    .imageContainer {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;

        .stockPhoto {
            width: 300px;
            height: 225px;
            margin-left: auto;
            margin-right: auto;
            margin-top: -6%;
            margin-bottom: -6%;
        }

        .copyright {
            font-size: 12px;
        }
    }

    .vehicleInfo {
        margin-top: -16px;

        .short {
            display: none;
        }

        :global(.kmx-table) {
            display: table;
            margin: 0;

            td {
                vertical-align: bottom;
                padding-bottom: size('s');

                p {
                    margin-block-start: 0;
                    margin-block-end: 0;
                }

                :global(.kmx-typography--headline-2) {
                    font-weight: normal;
                }
            }

            tr:last-of-type {
                height: 0px;
            }
        }
    }
}
