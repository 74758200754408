@import '~@kmx/legos-theme/src/spacing/functions';
@import '~@kmx/legos-theme/src/colors';

.container {
    .featuresLoadingProgress {
        float: right;
    }

    .styleWrapper {
        margin-top: -8px;
    }

    .driveWrapper,
    .transmissionWrapper {
        margin-top: size(m);
    }

    :global(.kmx-button) {
        float: right;
    }

    .features h3 {
        margin-top: size(l);
    }

    .features:after {
        content: '';
        display: table;
        clear: both;
    }

    .showMoreButton,
    .hideAdditionalStylesButton,
    .showStandardFeaturesButton {
        color: kmx-color('blue', 700) !important;
        width: 100%;
    }

    p {
        margin: unset;
        color: kmx-color('slate', 900);
    }

    .stylesInfoTitle {
        font-weight: bold;
        color: kmx-color('slate', 900);
    }

    .stylesInfo {
        margin: 8px 0;
    }
}
