@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~@kmx/legos-theme/src/spacing/functions';
@import '~@kmx/legos-theme/src/colors';
@import '~sass-mq';

.icoStepperParent {
    container-type: inline-size;

    .icoStepperContainer {
        margin-left: auto;
        margin-right: auto;
        max-width: 868px;
        width: 100%;

        .submitButtonContainer {
            margin-left: size(s);
            margin-right: size(s);
            margin-top: size(l);

            button {
                border-radius: 8px;
                height: 48px;
                width: 100%;
            }

            .reviewDetailsText {
                margin: 10px 25px;
                color: kmx-color('slate', 900);
                text-align: center;
            }
        }
    }

    .icoStepperContainer.offer {
        width: 100%;
        max-width: 564px;

        :global(.kmx-stepper--step):not(:global(.kmx-ico-step-vehicle)) {
            margin: 16px 8px;
        }
    }
}

// Desktop view styling for the ICO stepper (window > 900px)
@container (width > 900px) {
    // Until the customer submits, always hide the collapsed vehicle step header on a desktop
    .icoStepperContainer.inProgress :global(.kmx-ico-step-vehicle .kmx-stepper--step-header) {
        display: none;
    }

    // Until the customer submits, always show the vehicle step body in desktop view
    .icoStepperContainer.inProgress :global(.kmx-ico-step-vehicle .kmx-stepper--step-body) {
        display: block;
    }

    .icoStepperContainer.preEligibility {
        :global(.kmx-stepper--step) {
            width: 100%;
        }
    }

    // Set a fixed width for each step and float all steps to the right...
    .icoStepperContainer.inProgress :global(.kmx-stepper--step),
    .submitButtonContainer {
        width: 414px;
        float: right;
        margin-bottom: 0px;
    }

    // ... except the vehicle step which should be floated to the left
    .icoStepperContainer.inProgress :global(.kmx-stepper--step.kmx-ico-step-vehicle) {
        float: left;
        position: sticky;
        top: 16px; // This 16px matches the margin-top value of the .kmx-stepper--step class
    }

    .icoStepperContainer {
        // Add enough left-margin to all the right-floated steps to make sure they don't wrap underneath a potentially small-height vehicle step
        :global(.kmx-stepper--step):not(:global(.kmx-ico-step-vehicle)),
        .submitButtonContainer {
            margin-left: 16px !important;
        }
    }

    // Clear all the floats
    .icoStepperContainer:after {
        content: '';
        display: table;
        clear: both;
    }
}
