@import '~@kmx/legos-theme/src/colors';
@import '~@kmx/legos-theme/src/spacing/functions';

.stepHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $white;
    outline: none;
    border-width: 1px;
    border-style: solid;
    border-color: kmx-color('slate', 200);
    border-radius: size('s');
    cursor: pointer;
    padding: size('m');

    .completionIcon svg {
        position: relative;
        top: -3px;
    }
}

.stepHeader:active,
.stepHeader:hover:not(.disabled) {
    border-width: 1px;
    border-color: kmx-color('blue', 600);
}

.stepHeader.disabled {
    background: kmx-color('slate', 200);
    color: kmx-color('slate', 400);
    border: 0;
    cursor: not-allowed;
}

.stepHeader.hidden {
    display: none;
}

.stepHeader .completionIcon {
    height: size('m');
    width: size('m');
    fill: currentColor;

    :global(.kmx-progress-circular-indeterminate) {
        margin-top: size('xxs');
    }
}

.stepHeader.complete .completionIcon {
    color: kmx-color('green', 600);
}
