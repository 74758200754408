@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~sass-mq';

.pillRadio {
    @include mq($from: large) {
        display: none !important;
    }
}

.legosRadio {
    @include mq($until: large) {
        display: none !important;
    }
}
