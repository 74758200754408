@import '~@kmx/legos-theme/src/spacing/functions';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~sass-mq';

.offerDisclaimer {
    margin: 0 size(s);
    text-align: left;
    max-width: 564px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 8px;

    @include mq($from: large) {
        margin-top: size(m);

        .section {
            margin-bottom: 1em;
        }
    }
}
