@import '~@kmx/legos-theme/src/colors';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~@kmx/legos-theme/src/spacing/functions';
@import '~sass-mq';

.offerContainer {
    text-align: center;
    max-width: 564px;
    margin-left: auto;
    margin-right: auto;
    margin-top: size('2xl');
    margin-bottom: size(xl);

    @include mq($from: large) {
        margin-bottom: size(m);
    }

    .offerInfo {
        background: kmx-color('blue', 600);
        color: $white;
        margin: size(s);
        margin-bottom: 40px;
        padding-top: 40px;
        padding-bottom: 20px;
        border-radius: size(s);

        @include mq($from: large) {
            padding-top: size(xl);
        }

        h3 {
            margin-top: 0;

            @include mq($until: large) {
                margin-bottom: 0;
            }
        }

        h1 {
            margin-bottom: 0;
            margin-top: size(s);

            @include mq($from: large) {
                margin-top: 14px;
            }
        }

        .swoosh {
            display: none;
            height: 64px;
            width: 146px;
            margin-top: -20px;
            margin-bottom: -18px;

            @include mq($from: large) {
                height: 83px;
                width: 189px;
            }
        }

        .offerCode {
            margin-bottom: 0;
            margin-top: 32px;

            @include mq($from: large) {
                font-size: 20px;
            }
        }

        .expiration {
            margin-top: size(s);
            margin-bottom: 16px;
        }

        .walletPass {
            width: 128px;
            cursor: pointer;
        }

        .walletPassButton {
            height: 50px;
            width: 152px;
            background-color: transparent;
            color: transparent;
            border: none;
        }

        .offerCta {
            width: 256px;
            margin-top: 4px;
        }

        .offerCtaIcon {
            fill: kmx-color('blue', 900);
            height: size(m);
            width: size(m);
        }

        h1,
        h3,
        p {
            color: $white;
        }

        .nextActionsContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .offerDisclaimer {
        background: kmx-color('yellow', 100);
        border-radius: 12px;
        margin: 24px 8px 0px;
        padding: 2px 35px;

        .disclaimerText {
            color: kmx-color('blue', 900);
        }
    }

    .appointmentContainer {
        width: 100%;
        max-width: 468px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;

        @include mq($from: large) {
            margin-bottom: size(l);
        }

        h3 {
            margin-top: 0.75em;
            margin-bottom: 0;
        }

        p {
            margin-top: 12px;
            color: kmx-color('slate', 900);
            margin-bottom: 16px;
        }

        button {
            border-radius: 8px;
            height: 48px;
            width: 100%;
            max-width: 343px;
        }

        .emailButton {
            margin-top: size(m);
        }

        .saveOffer,
        .editVehicle,
        .viewOffer {
            margin-top: 16px;
            color: kmx-color('blue', 600);
        }

        .headline,
        .description,
        .saveOfferTitle {
            max-width: 343px;
            margin-left: auto;
            margin-right: auto;

            @include mq($from: medium) {
                max-width: 381px;
            }
        }

        .headline {
            margin-top: 44px;
            font-size: 25px;
        }

        .description {
            margin-bottom: 10px;
            white-space: pre-line;
        }

        .saveOfferTitle {
            margin-top: 30px;
            font-size: 20px;
        }
    }

    .pickupTestContainer {
        max-width: 480px;

        @include mq($from: 0px, $until: 450px) {
            max-width: 400px;
        }

        .appointmentHeader {
            max-width: 414px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
        }

        .setMyAppointment {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 28px;
            color: kmx-color('slate', 900);
        }

        .appointmentOrPickupContainer {
            display: flex;
            justify-content: space-around;

            @include mq($from: 0px, $until: 450px) {
                flex-direction: column;
            }

            @supports not (-ms-ime-align: auto) {
                justify-content: space-evenly;
            }

            .appointmentOrPickup {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 16px;

                .appointmentOrPickupIcon {
                    fill: kmx-color('blue', 900);
                    margin-bottom: 8px;
                }

                .appointmentOrPickupButton {
                    margin-bottom: 8px;
                }

                .pickupFinePrint {
                    color: kmx-color('slate', 700);
                    font-size: 12px;
                }
            }
        }

        .saveOffer,
        .editVehicle,
        .viewOffer {
            max-width: 400px;
        }
    }
}

.shopInfoContainer {
    background-color: #eff7fc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    margin: 48px auto 4px auto;
    padding: 24px;
    text-align: left;

    h3 {
        align-self: stretch;
        margin: 0;
    }

    p {
        align-self: stretch;
        margin: 24px 0;
    }

    .shopCta {
        background-color: kmx-color('blue', 600) !important;
        border-radius: 8px;
        color: $white !important;
        height: 48px;
        max-width: 343px;
        margin-top: 4px;
        width: 100%;
    }

    .shopCtaIcon {
        margin-left: 8px;
    }

    .tradeInTaxContainer {
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 24px;

        svg {
            margin: 8px;
        }

        p {
            max-width: 133px;
            margin: 0 24px 0 0;
        }

        p:last-child {
            align-self: auto;
            flex-grow: 1;
            text-align: center;
        }
    }
}

.emailOfferModal:global(.kmx-dialog) {
    // "X" close button of the modal
    .closeButton,
    .closeButton:focus {
        position: absolute;
        top: size(l);
        right: size(l);
        padding: 2px;
        min-width: 0px;
        height: size(l);
        outline-style: none;

        svg {
            height: size(l);
            width: size(l);
            fill: $white;
        }
    }

    // Hiding the footer with CSS because LEGOS requires it to be present :-/
    :global(footer.mdc-dialog__footer) {
        display: none;
    }

    :global(.mdc-dialog__surface) {
        background-color: transparent;

        // Need to add this margin to get the modal centered on screen widths < 640 px  ¯\_(ツ)_/¯
        @include mq($until: 640px) {
            margin-left: 14px;
        }
    }

    // Modal body
    section:global(.mdc-dialog__body) {
        text-align: center;
        margin-top: 0;
        padding: 0;

        .header {
            background: kmx-color('blue', 600);
            padding-top: size('4xl');
            padding-bottom: size('3xl');
            padding-left: size(l);
            padding-right: size(l);
            border-top-left-radius: size(s);
            border-top-right-radius: size(s);

            :global(.kmx-typography--display-3),
            :global(.kmx-typography--body-1) {
                color: $white;
            }

            :global(.kmx-typography--display-3) {
                margin-bottom: 1em;
                margin-top: 0;
            }

            :global(.kmx-typography--body-1) {
                max-width: 343px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .form {
            background-color: $white;
            padding-top: size(l);
            padding-bottom: size('2xl');
            padding-left: size(l);
            padding-right: size(l);
            border-bottom-left-radius: size(s);
            border-bottom-right-radius: size(s);

            .emailAddressContainer {
                max-width: 330px;
                margin-left: auto;
                margin-right: auto;

                :global(.kmx-text-area-helper-text) {
                    margin-top: 0;
                    text-align: left;
                }
            }

            button:global(.kmx-button--primary) {
                max-width: 360px;
                width: 100%;
                margin-top: size(xl);
            }
        }
    }
}

// Ethnios shows in an iframe appended to the body, and we're
// forced to estimate the size of the modal containing the iframe
:global(.ethnios-modal) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50vh - 200%));
    z-index: 1000;
    width: 100%;
    width: calc(100% - 30px);
    max-width: 828px;
    overflow: auto;
    z-index: 100;
    opacity: 0;
    transition:
        1s transform ease,
        1s opacity ease;
}

:global(.ethnios-modal iframe) {
    width: 100%;
    border: none;
    border-radius: 10px;

    @include mq($from: 300px) {
        height: 630px;
    }

    @include mq($from: medium) {
        height: 540px;
    }

    @include mq($from: large) {
        height: 510px;
    }
}

:global(.ethnios-modal--active) {
    transform: translate(-50%, -50%);
    opacity: 1;
}

:global(.ethnios-modal__wrapper) {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(42, 52, 61, 0.4);
}

:global(.ethnios-modal__close) {
    position: absolute;
    width: 20px;
    height: 20px;
    background: none;
    border: 0;
    top: 8px;
    right: 24px;
    outline: 0;
    cursor: pointer;
}

:global(.ethnios-modal__close:after),
:global(.ethnios-modal__close:before) {
    content: '';
    position: absolute;
    top: 50%;
    left: 5%;
    width: 90%;
    height: 2px;
    background: black;
}

:global(.ethnios-modal__close:after) {
    transform: rotate(45deg);
}

:global(.ethnios-modal__close:before) {
    transform: rotate(-45deg);
}

.offerCtaStyle {
    margin-left: 8px;
    margin-right: 8px;
}
