@import '~@kmx/legos-theme/src/colors/index';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~@kmx/legos-theme/src/typography/mixins';
@import '~sass-mq';

.icoIneligible {
    text-align: center;
    padding: 0 8px;

    h4 {
        margin-top: 18px;
        margin-bottom: 8px;
    }

    p {
        max-width: 502px;
        margin-left: auto;
        margin-right: auto;
    }

    :global(.kmx-button--primary) {
        height: 48px;
        margin-top: 0px;
        border-radius: 8px;
        min-width: 220px;

        @include mq($from: medium) {
            min-width: 300px;
            margin-top: 16px;
        }
    }

    .takePhotoImage {
        width: 150px;
        height: 120px;

        @include mq($from: large) {
            width: 200px;
            height: 161px;
        }
    }

    .continueButton {
        margin-top: 16px;
    }

    .cardContainer {
        display: inline-flex;
        flex-direction: column;
    }

    .card {
        @include mq($from: large) {
            max-width: 380px;
        }
        text-align: left;
    }

    .ctaTextPrompt {
        margin-bottom: 0px;
    }
}
