@import '~@kmx/legos-theme/src/colors';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~@kmx/legos-theme/src/spacing/functions';
@import '~sass-mq';

.kbbConditionDialogContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .helpIcon {
        fill: kmx-color('blue', 700);
        width: 67px;
        height: 67px;
    }

    .kbbHeader {
        font-family: $kmx-typography-font-main;
        color: kmx-color('blue', 900);
        font-size: 25px;
        margin-top: size(m);
        margin-bottom: size(s);
    }

    .kbbBody {
        font-family: $kmx-typography-font-alt;
        // keep the scrollable content's height roughly tied to the viewport height
        max-height: calc(50vh - 30px);
        overflow: auto;
    }
}
