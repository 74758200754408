@import '~@kmx/legos-theme/src/spacing/functions';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~sass-mq';
@import '~@kmx/legos-theme/src/colors';

.container {
    fieldset {
        padding-bottom: size('m');
    }
    legend {
        padding-top: 0px;
        margin-top: size('s');
        margin-bottom: size('s');
    }

    :global(.kmx-text-field) {
        margin-top: -4px;
        margin-bottom: size('s');
    }

    :global(.kmx-form-field) {
        display: flex;
    }

    .cardQuestionTitle {
        font-weight: 700;
        font-size: 14px;
    }

    .cardSubtext {
        font-size: 12px;
        color: kmx-color('slate', 600);
    }

    .kbbDialogLink {
        font-size: 12px;
        color: kmx-color('blue', 600);
        cursor: pointer;
        font-family: $kmx-typography-font-alt;
        line-height: normal;
        text-decoration: underline;
    }

    .conditionQuestionHelperTextContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 8px 0;

        .conditionQuestionHelperText {
            margin: 0;
            font-size: 14px;
            cursor: pointer;
            color: kmx-color('blue', 600);
            text-decoration: underline;
            background: transparent;
            border: none;
            padding: 0;
        }

        .conditionQuestionHelperTextIcon {
            height: 16px;
            fill: kmx-color('blue', 600);
            padding-left: 6px;
        }
    }

    .afterMarketModificationAlert {
        background-color: #ffedef;
        padding: 1rem;
        color: #6b0a0a;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;
        padding-top: 0;
        text-align: center;

        .afterMarketModificationAlertHeader {
            color: #6b0a0a;
            font-weight: 700;
            font-size: 16px;
            font-family: $kmx-typography-font-alt;
        }

        .afterMarketModificationAlertText {
            color: #6b0a0a;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 8px !important;
            width: 90%;
            margin-top: 8px !important;
        }

        .afterMarketModificationAlertList {
            margin-top: 0;
            margin-bottom: 0 !important;
        }

        .afterMarketModificationAlertImg {
            margin-top: 16px;
        }
    }

    .childrenQuestions {
        margin-left: 10px;
        padding-left: 12px;
        border-left: 2px solid kmx-color('slate', 300);

        .childrenLoading {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
