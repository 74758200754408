@import '~@kmx/legos-theme/src/colors';

.loadingIndicatorContainer {
    width: 100%;
    flex-grow: 1;
    background-color: kmx-color('blue', 600);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    img {
        max-width: 275px;
        max-height: 275px;
    }
}
