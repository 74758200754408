@import '~@kmx/legos-theme/src/spacing/functions';
@import '~@kmx/legos-theme/src/colors';

.validationSummary {
    display: flex;
    padding: size(m);
    background: kmx-color('red', 100);
    border-radius: size(xxs);

    svg {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: size(m);
        height: size(l);
        width: size(l);
        fill: kmx-color('red', 900);
    }

    :global(h4.kmx-typography--headline-2),
    :global(p.kmx-typography--body-1) {
        color: kmx-color('red', 900);
        margin: 0;
    }

    :hover {
        cursor: pointer;
    }
}
