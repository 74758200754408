@import '~@kmx/legos-theme/src/spacing/functions';
@import '~@kmx/legos-theme/src/colors';

.invalid {
    .top {
        display: flex;

        .questionWrapper {
            margin-right: size(s);
            flex-grow: 100;

            // Global LEGOS form validation style overrides (Select)
            :global(.kmx-select.mdc-select) {
                :global(.mdc-select__native-control),
                :global(.mdc-select__native-control):hover {
                    border-bottom-color: kmx-color('red', 600);
                }

                :global(.mdc-floating-label),
                :global(.mdc-select__native-control:focus) ~ :global(.mdc-floating-label) {
                    color: kmx-color('red', 600);
                }
            }
        }

        .validationIcon {
            fill: kmx-color('red', 600);
            height: size(m);
            width: size(m);
            margin-top: 0.75em;
        }
    }

    :global(.mdc-text-field-helper-text).validationMessage {
        color: kmx-color('red', 600);
        opacity: 1;
        margin-top: -12px;
        margin-bottom: 12px;
    }
}

.overlayIcon {
    .top {
        .questionWrapper {
            margin-right: 0;
        }

        .validationIconOuter {
            position: relative;

            .validationIconInner {
                position: absolute;
                left: -21px;
                top: 14px;
                background: $white;
            }
        }
    }

    :global(.mdc-text-field-helper-text).validationMessage {
        margin-top: 0px;
    }
}
