@import '~@kmx/legos-theme/src/colors';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~@kmx/legos-theme/src/spacing/functions';
@import '~sass-mq';

.icoDialog {
    padding-top: 20px;

    .icoDialogContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .icoDialogBottomCloseButton {
            margin-top: size(l);
            width: 236px;
        }
    }
}

.icoDialog:global(.kmx-dialog) {
    z-index: 800;

    // "X" close button of the modal
    .closeButton,
    .closeButton:focus {
        position: absolute;
        top: size(m);
        right: size(m);
        padding: 2px;
        min-width: 0px;
        height: size(l);
        outline-style: none;

        svg {
            height: size(l);
            width: size(l);
        }
    }

    // Hiding the footer with CSS because LEGOS requires it to be present :-/
    :global(footer.mdc-dialog__footer) {
        display: none;
    }

    :global(.mdc-dialog__surface) {
        overflow-y: auto;
        max-height: 100vh;
        border-radius: 8px;
        // Need to add this margin to get the modal centered on screen widths < 640 px  ¯\_(ツ)_/¯
        @include mq($until: 640px) {
            margin-left: 14px;
        }
    }
}
