@import '~@kmx/legos-theme/src/colors';
@import '~@kmx/legos-theme/src/spacing/functions';

.stepBody {
    background: $white;
    border-width: 1px;
    border-style: solid;
    border-color: kmx-color('slate', 200);
    border-radius: size('s');
    padding: size('m');

    > div > h3:first-of-type {
        margin-top: 0;
        margin-bottom: 1em;
    }
}

.stepBody.hidden {
    display: none;
}
