.header {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    h3 {
        margin: 0;
    }
}

.infoButton {
    padding: 4px 8px;
    display: flex;
}

.tooltip {
    max-width: 365px;
    z-index: 1;
    padding: 0.5rem 0 0.5rem 1rem;
}

.cautionAlert {
    margin-bottom: 16px;
}
