@import '~@kmx/legos-theme/src/colors';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~@kmx/legos-theme/src/spacing/functions';
@import '~sass-mq';

.kbbValuationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: size(s);

    .compareToKbb {
        font-family: $kmx-typography-font-main;
        color: kmx-color('blue', 900);
        line-height: 1;
        letter-spacing: normal;
        font-weight: bold;
        font-size: 25px;
        margin-top: size(xl);
        margin-bottom: size(m);
    }

    .kbbWidgetFrame {
        border: unset;
        // setting min-height will preserve the aspect ratio, which is roughly 300/385 = .779
        min-height: 385px;
    }

    .kbbWidgetAndDate {
        padding: size(l) 1% size(s) 1%;
        border: 1px solid #dfe0e0;
        background: white;
        border-radius: 4px;
        width: 100%;
        max-width: 366px;

        @include mq($from: medium) {
            padding: size(l) size(m) size(s) size(m);
        }

        .kbbGenerationDate {
            font-family: $kmx-typography-font-alt;
            color: #767676;
            font-size: 12px;
        }
    }

    &.kbbOfferPesentationTest {
        .compareToKbb {
            margin-top: 0;
        }
        .kbbWidgetAndDate {
            padding-bottom: size(l);
        }
        .kbbConditionSelector {
            margin-top: size(m);
        }
    }
}
