@import '~@kmx/legos-theme/src/colors';
@import '~@kmx/legos-theme/src/elevation';
@import '~@kmx/legos-theme/src/layout/_variables.scss';
@import '~@kmx/legos-theme/src/typography/mixins';
@import '~sass-mq';

.kmxCard {
    border: 1px solid kmx-color('slate', 300);
    transition: 50ms;
    background-color: white;
    border-radius: 8px;
    margin-top: 16px;
    overflow: hidden;
    padding: 16px;
    position: relative;

    @include mq($until: large) {
        .kmx-typography--body-2 {
            font-size: rem-calc(12px);
        }
    }

    .svgContainer {
        text-align: center;

        svg {
            width: 32px;
            fill: kmx-color('blue', 900);
        }
    }
}

@include mq($from: medium) {
    .kmxCard {
        display: flex;
        flex-direction: row;
        align-items: center;

        .svgContainer {
            margin-right: 16px;
        }
    }
}

.kmxCard.selected {
    border: 1px solid kmx-color('blue', 600) !important;
    // padding: 15px;
    .kmxCardSelectedIndicator:before {
        transform: rotate(45deg) translateY(0px);
    }

    @include kmx-elevation(4);

    &:hover {
        @include kmx-elevation(4);
    }
}

.kmxCard.clickable {
    .kmx-typography--headline-2 {
        color: kmx-color('slate', 900);
    }

    cursor: pointer;

    &:hover:not(.kmx-card--selected) {
        border: 1px solid #4d5055;
        // padding: 15px;
    }
}

.kmxCardSelectedIndicator {
    padding: 0px 4px;
    position: absolute;
    top: 0;
    right: 0;

    &:before {
        background-color: kmx-color('blue', 600);
        content: '';
        height: 70px;
        position: absolute;
        right: -34px;
        transform: rotate(45deg) translateY(-35px);
        transition: 150ms;
        top: -40px;
        width: 60px;
    }

    svg {
        position: relative;
        z-index: 1;
        fill: #fff;
        height: 24px;
        width: 16px;
    }
}

.kmxCardFooter {
    padding-top: 3px;
}
