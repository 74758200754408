.icoActiveStoreAppraisal {
    text-align: center;
    max-width: 564px;
    margin: 0 auto;
    padding: 0 32px;
    align-items: center;
    height: 100%;

    p {
        line-height: 1;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 30px;
    }

    .button {
        max-width: 264px;
        height: 40px;
        margin-top: 10px;
        padding: 0;
        width: 100%;
        line-height: inherit;
    }

    .image {
        margin-top: 8px;
        max-width: 288px;
        width: 100%;
        height: auto;
    }
}
